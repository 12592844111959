
.Graphic_-_2yUUg {
    width: 105%;
    max-width: 700px;
    margin-left: calc(-1 * var(--space-md))
}
@media (min-width: 600px) {
.Graphic_-_2yUUg {
        width: 100%;
        max-width: 100%;
        margin-left: calc(-1 * ((100vw - 554px) / 2))
}
}
@media (min-width: 992px) {
.Graphic_-_2yUUg {
        width: 100%;
        max-width: 100%;
        margin-left: calc(-1 * var(--space-lg))
}
}
@media (min-width: 1264px) {
.Graphic_-_2yUUg {
        width: 100%;
        max-width: 100%;
        margin-left: calc(-1 * ((100vw - 1200px) / 2))
}
}
@media (min-width: 1440px) {
.Graphic_-_2yUUg {
        width: calc(100% + 120px);
        max-width: calc(100% + 120px);
        margin-left: -120px
}
}
