
:root {
    --section-max-width: 1440px;
    --triangle-left-position: calc(50% - 470px);
    --triangle-right-position: calc(50% + 470px);
    --triangle-mobile-bg-size: calc(100% - var(--space-xxl));
    --triangle-width-showing: calc(50vw + 250px - 470px);
    --triangle-min-height: calc(2 * var(--triangle-width-showing));
}
.Section_-_3yPeB {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    max-width: 1440px;
    max-width: 1440px;
    max-width: var(--section-max-width);
    padding: 120px 0;
    margin: auto;
    overflow-x: hidden
}
.Section_-_3yPeB.theme_normal_-_R75R0,
    .Section_-_3yPeB.theme_triangle-left_-_3EkTx,
    .Section_-_3yPeB.theme_triangle-left-alt_-_1ngvM,
    .Section_-_3yPeB.theme_triangle-right_-_1k6WU,
    .Section_-_3yPeB.theme_triangle-top-right_-_2yIA7 {
        color: var(--color-gray-200);

        /* background-color: var(--color-white); removed so sections can be squeezed closer together */
}
.Section_-_3yPeB.theme_primary_-_SBfSf {
        color: var(--color-white);
        background-color: var(--color-primary);
}
.Section_-_3yPeB.theme_dark_-_1u7BF {
        color: var(--color-white);
        background-color: var(--color-gray-500);
}
.Section_-_3yPeB.theme_triangle-left_-_3EkTx {
        background-image: url("/assets/images/landing-page/triangles/left.png");
        background-repeat: no-repeat;
        background-position: 0 100%;
        background-size: calc(100% - var(--space-xxl));
        background-size: calc(100% - var(--space-xxl));
        background-size: var(--triangle-mobile-bg-size)
}
@media (min-width: 600px) {
.Section_-_3yPeB.theme_triangle-left_-_3EkTx {
            min-height: 1000px;
            background-size: 500px
}
}
@media (min-width: 992px) {
.Section_-_3yPeB.theme_triangle-left_-_3EkTx {
            min-height: calc(2 * calc(50vw + 250px - 470px));
            min-height: calc(2 * calc(50vw + 250px - 470px));
            min-height: var(--triangle-min-height);
            background-position: calc(50% - 470px) 50%;
            background-position: calc(50% - 470px) 50%;
            background-position: var(--triangle-left-position) 50%
}
}
@media (min-width: 1440px) {
.Section_-_3yPeB.theme_triangle-left_-_3EkTx {
            min-height: 1000px
}
}
.Section_-_3yPeB.theme_triangle-left-alt_-_1ngvM {
        background-image: url("/assets/images/landing-page/triangles/left-alt.png");
        background-repeat: no-repeat;
        background-position: 0 100%;
        background-size: calc(100% - var(--space-xxl));
        background-size: calc(100% - var(--space-xxl));
        background-size: var(--triangle-mobile-bg-size)
}
@media (min-width: 600px) {
.Section_-_3yPeB.theme_triangle-left-alt_-_1ngvM {
            min-height: 1000px;
            background-size: 500px
}
}
@media (min-width: 992px) {
.Section_-_3yPeB.theme_triangle-left-alt_-_1ngvM {
            min-height: calc(2 * calc(50vw + 250px - 470px));
            min-height: calc(2 * calc(50vw + 250px - 470px));
            min-height: var(--triangle-min-height);
            background-position: calc(50% - 470px) 50%;
            background-position: calc(50% - 470px) 50%;
            background-position: var(--triangle-left-position) 50%
}
}
@media (min-width: 1440px) {
.Section_-_3yPeB.theme_triangle-left-alt_-_1ngvM {
            min-height: 1000px
}
}
.Section_-_3yPeB.theme_triangle-right_-_1k6WU {
        background-image: url("/assets/images/landing-page/triangles/right.png");
        background-repeat: no-repeat;
        background-position: 100% 100%;
        background-size: calc(100% - var(--space-xxl));
        background-size: calc(100% - var(--space-xxl));
        background-size: var(--triangle-mobile-bg-size)
}
@media (min-width: 600px) {
.Section_-_3yPeB.theme_triangle-right_-_1k6WU {
            min-height: 1000px;
            background-size: 500px
}
}
@media (min-width: 992px) {
.Section_-_3yPeB.theme_triangle-right_-_1k6WU {
            min-height: calc(2 * calc(50vw + 250px - 470px));
            min-height: calc(2 * calc(50vw + 250px - 470px));
            min-height: var(--triangle-min-height);
            background-position: calc(50% + 470px) 50%;
            background-position: calc(50% + 470px) 50%;
            background-position: var(--triangle-right-position) 50%
}
}
@media (min-width: 1440px) {
.Section_-_3yPeB.theme_triangle-right_-_1k6WU {
            min-height: 1000px
}
}
.Section_-_3yPeB.theme_triangle-top-right_-_2yIA7 {
        min-height: calc(50vw + 250px - 470px);
        min-height: calc(50vw + 250px - 470px);
        min-height: var(--triangle-width-showing);
        background-image: url("/assets/images/landing-page/triangles/top-right.png");
        background-repeat: no-repeat;
        background-position: 100% 0%;
        background-size: 180px
}
@media (min-width: 600px) {
.Section_-_3yPeB.theme_triangle-top-right_-_2yIA7 {
            background-size: 275px
}
}
@media (min-width: 992px) {
.Section_-_3yPeB.theme_triangle-top-right_-_2yIA7 {
            background-position: calc(50% + 470px) 0%;
            background-position: calc(50% + 470px) 0%;
            background-position: var(--triangle-right-position) 0%;
            background-size: 500px
}
}
@media (min-width: 1440px) {
.Section_-_3yPeB.theme_triangle-top-right_-_2yIA7 {
            min-height: 500px
}
}
.Container_-_3IMK7 {
    position: relative;
    width: calc(100% - var(--space-xl));
    padding: var(--space) 0;
    margin: auto
}
@media (min-width: 992px) {
.Container_-_3IMK7 {
        width: calc(100% - var(--space-xxl))
}
}
.Container_-_3IMK7.container_sm_-_1-3sZ {
        max-width: 800px;
}
.Container_-_3IMK7.container_md_-_bGQRD {
        max-width: 1000px;
}
.Container_-_3IMK7.container_lg_-_1m4b_ {
        max-width: 1200px;
}
.Grid_-_2VgeV {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 600px;
    margin: auto
}
@media (min-width: 992px) {
.Grid_-_2VgeV {
        max-width: none
}
}
@media (max-width: 992px) {
.Item_-_dxQEW.media_-_3DbzO {
            -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                    order: 2
}
}
.Item_-_dxQEW.media_-_3DbzO.theme_triangle-right_-_1k6WU {
            -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                    order: 2;
}
.Item_-_dxQEW.media_-_3DbzO.theme_triangle-right_-_1k6WU,
        .Item_-_dxQEW.media_-_3DbzO.theme_triangle-left_-_3EkTx,
        .Item_-_dxQEW.media_-_3DbzO.theme_triangle-left-alt_-_1ngvM {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            min-height: calc(2 * (100vw - var(--space-xxl)) - 240px)
}
@media (min-width: 600px) {
.Item_-_dxQEW.media_-_3DbzO.theme_triangle-right_-_1k6WU,
        .Item_-_dxQEW.media_-_3DbzO.theme_triangle-left_-_3EkTx,
        .Item_-_dxQEW.media_-_3DbzO.theme_triangle-left-alt_-_1ngvM {
                min-height: 700px
}
}
@media (max-width: 992px) {
.Item_-_dxQEW.content_-_HP-0h {
            -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                    order: 1
}
}
.Item_-_dxQEW.content_-_HP-0h.theme_triangle-right_-_1k6WU {
            -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                    order: 1;
}
.Title_-_PdiMC {
    font-size: var(--font-size-h3);
    text-align: center
}
@media (min-width: 600px) {
.Title_-_PdiMC {
        font-size: var(--font-size-h2)
}
}
@media (min-width: 992px) {
.Title_-_PdiMC {
        text-align: left
}
}
.Title_-_PdiMC.theme_normal_-_R75R0,
    .Title_-_PdiMC.theme_triangle-left_-_3EkTx,
    .Title_-_PdiMC.theme_triangle-left-alt_-_1ngvM,
    .Title_-_PdiMC.theme_triangle-right_-_1k6WU,
    .Title_-_PdiMC.theme_triangle-top-right_-_2yIA7 {
        color: var(--color-gray-500);
}
.Title_-_PdiMC.theme_primary_-_SBfSf,
    .Title_-_PdiMC.theme_dark_-_1u7BF {
        color: var(--color-white);
}
.Text_-_4iSDX {
    text-align: center
}
@media (min-width: 992px) {
.Text_-_4iSDX {
        text-align: left
}
}
.Icon_-_2iUwF {
    display: block;
    height: 80px;
    margin: 0 auto var(--space-md) auto
}
@media (min-width: 992px) {
.Icon_-_2iUwF {
        margin: 0 0 var(--space-md) 0
}
}
