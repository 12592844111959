
.MobileDropdown_-_2OuMU {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1002;
    background: var(--color-white)
}
.MobileDropdown_-_2OuMU.landing-page_-_2ge0K {
        top: 80px;
}
.MobileDropdown_-_2OuMU.standard_-_1QwMw {
        top: 96px;
}
.MobileDropdown_-_2OuMU.sidebar_-_1Siky {
        top: 0;
}
.CloseIcon_-_3DSqJ {
    position: absolute;
    top: 11px;
    right: 11px;
    cursor: pointer;
}
