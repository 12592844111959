
.FullSize_-_3rUv5 {
    display: none;
}
.HeaderLink_-_my7W6 {
    color: #fff;
    font-weight: bold;
}
.HelpLink_-_3QOL2 {
    margin-right: var(--space-lg);
}
.MenuLink_-_1SWsA,
.MenuLink_-_1SWsA:visited {
    margin-right: 44px;
    font-size: var(--font-size-h6);
    font-weight: var(--font-weight-semi);
    color: var(--color-white);
    text-decoration: none;
}
.HamburgerMenu_-_1aw6C {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 70px;
    height: 80px;
    font-size: var(--font-size-h4);
    color: var(--color-white)
}
.HamburgerMenu_-_1aw6C.ActiveLink_-_3E-dd {
        color: var(--font-color);
        background: var(--color-white);
}
.HamburgerMenu_-_1aw6C.LandingPage_-_2J8j1 {
        height: 96px;
}
@media only screen and (min-width: 780px) {
.FullSize_-_3rUv5 {
        display: block;
}
.HamburgerMenu_-_1aw6C {
        display: none;
}
}
