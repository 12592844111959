
@media (min-width: 992px) {
.Carousel_-_1PCV_ {
        padding: 0 100px
}
}

/* TODO: Fix grid valign */
.Grid_-_1oZPg {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    grid-gap: 0;
    gap: 0
}
@media (min-width: 600px) {
.Grid_-_1oZPg {
        grid-gap: var(--space-xxl);
        gap: var(--space-xxl)
}
}
.Title_-_26-O8 {
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-bold);
    color: var(--color-gray-500);
    text-align: center
}
@media (min-width: 600px) {
.Title_-_26-O8 {
        font-size: var(--font-size-h3)
}
}
@media (min-width: 992px) {
.Title_-_26-O8 {
        font-size: var(--font-size-h2);
        text-align: left
}
}
.Text_-_2w_Vv {
    min-height: 84px;
    text-align: center
}
@media (min-width: 600px) {
.Text_-_2w_Vv {
        min-height: 56px
}
}
@media (min-width: 992px) {
.Text_-_2w_Vv {
        text-align: left
}
}
.Icon_-_11902 {
    margin-bottom: var(--space-md);
}

/* Images
---------------------------------------------- */
.Images_-_2tMKb {
    position: relative;
    z-index: 0;
    width: calc(100% - var(--space-xl));
    max-width: 376px;
    margin: auto;
}
.Item_-_2gHV6 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    -webkit-box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
    -webkit-transition: 0.5s var(--transition-easing);
    transition: 0.5s var(--transition-easing)
}
.Item_-_2gHV6:first-child {
        position: relative;
}
.Item_-_2gHV6.active_-_TcgKw {
        -webkit-box-shadow: 0 2px 12px rgba(0, 0, 0, 0.5);
                box-shadow: 0 2px 12px rgba(0, 0, 0, 0.5);
}
.Img_-_1juc6 {
    width: 100%;
    cursor: pointer;
    opacity: 0.5;
    -webkit-transition: opacity 0.5s var(--transition-easing) 0.25s;
    transition: opacity 0.5s var(--transition-easing) 0.25s
}
.Img_-_1juc6.active_-_TcgKw {
        opacity: 1;
}

/* Nav
---------------------------------------------- */
.NavArrow_-_21AEf {
    display: none
}
@media (min-width: 992px) {
.NavArrow_-_21AEf {
        position: absolute;
        top: calc(50% - 32.5px);
        display: block;
        width: 75px;
        height: 75px;
        cursor: pointer
}
}
.NavArrow_-_21AEf.prev_-_1kMiS {
        left: -25px;
}
.NavArrow_-_21AEf.next_-_2kd_M {
        right: -25px;
}
.NavArrowContainer_-_D4nAj {
    position: relative;
    width: 75px;
    height: 75px;
    -webkit-transition: -webkit-transform var(--transition);
    transition: -webkit-transform var(--transition);
    transition: transform var(--transition);
    transition: transform var(--transition), -webkit-transform var(--transition)
}
.NavArrowContainer_-_D4nAj::before,
    .NavArrowContainer_-_D4nAj::after {
        position: absolute;
        top: calc(50% - 2px);
        left: calc(50% - 10px);
        display: block;
        width: 24px;
        height: 4px;
        content: "";
        background: var(--color-gray-500);
        border-radius: 1px;
}
.NavArrowContainer_-_D4nAj::after {
        margin-top: -1px;
}
.NavArrowContainer_-_D4nAj.prev_-_1kMiS::before {
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
            -webkit-transform-origin: bottom left;
                    transform-origin: bottom left;
}
.NavArrowContainer_-_D4nAj.prev_-_1kMiS::after {
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
            -webkit-transform-origin: top left;
                    transform-origin: top left;
}
.NavArrowContainer_-_D4nAj.next_-_2kd_M::before {
            -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
            -webkit-transform-origin: bottom right;
                    transform-origin: bottom right;
}
.NavArrowContainer_-_D4nAj.next_-_2kd_M::after {
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
            -webkit-transform-origin: top right;
                    transform-origin: top right;
}
.NavArrow_-_21AEf:hover .NavArrowContainer_-_D4nAj.prev_-_1kMiS {
        -webkit-transform: translateX(-10%);
                transform: translateX(-10%);
}
.NavArrow_-_21AEf:hover .NavArrowContainer_-_D4nAj.next_-_2kd_M {
        -webkit-transform: translateX(10%);
                transform: translateX(10%);
}
.Nav_-_3_aZB {
    position: absolute;
    right: 0;
    bottom: calc(-1 * var(--space-xxxl));
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.NavItem_-_2eH0q {
    width: var(--space);
    height: var(--space);
    cursor: pointer;
    background: var(--color-gray-085);
    border: var(--space) solid var(--color-white);
    border-radius: 100%;
    -webkit-transition: var(--transition);
    transition: var(--transition)
}
.NavItem_-_2eH0q.active_-_TcgKw {
        background-color: var(--color-primary);
}
.NavItem_-_2eH0q:hover {
        background-color: var(--color-primary);
}
