
.Security_-_35cMu {
    padding-bottom: 160px;
}
.List_-_3RPBz {
    padding: 0;
    margin: 0;
    list-style: none
}
@media (min-width: 992px) {
.List_-_3RPBz {
        list-style: disc
}
}
.Item_-_1vlWK {
    text-align: center
}
@media (min-width: 992px) {
.Item_-_1vlWK {
        text-align: left
}
}
.Item_-_1vlWK + .Item_-_1vlWK {
        margin-top: var(--space);
}
.Item_-_1vlWK.graphic_-_2PBGh {
        font-size: 0;
}
.Graphic_-_2gtyO {
    width: 100%;
    max-width: 900px;
    margin-bottom: -7.5%;
}
