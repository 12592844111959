
.NotificationDropdown_-_3Le94 {
    position: fixed;
    top: 80px;
    right: 0;
    left: 0;
    z-index: 1003;
    max-height: 500px;
    background: var(--color-white);
    border-radius: var(--border-radius);
    -webkit-box-shadow: var(--elevation-12);
            box-shadow: var(--elevation-12);
}
.Header_-_3SbhD {
    padding: 0 var(--space);
    font-size: var(--font-size-text-xs);
    font-weight: bold;
    line-height: 28px;
    text-transform: uppercase;
    letter-spacing: 0.72px;
    border-bottom: 1px solid var(--color-gray-085);
}
.Footer_-_kTJ1K {
    font-size: var(--font-size-text-sm);
    font-weight: bold;
    line-height: 28px;
    text-align: center;
    border-top: 1px solid var(--color-gray-085);
}

/* Tablet and upward. */
@media only screen and (min-width: 780px) {
.NotificationDropdown_-_3Le94 {
        position: absolute;
        right: 414px;
        left: auto;
        width: 450px;
}
}
