
.mobile_display_-_16OVl {
    display: none
}
@media (max-width: 991px) {
.mobile_display_-_16OVl {
        display: inline-block
}
}
.desktop_display_-_1Qg9B {
    display: none
}
@media (min-width: 992px) {
.desktop_display_-_1Qg9B {
        display: inline-block
}
}
.Hero_-_MXblk {
    padding-bottom: var(--space-lg);
    overflow: hidden;
    background: url(/assets/images/landing-page/hero/background.jpg) no-repeat;
    background-position: top 0 right -10%;
    background-size: auto 100%
}
@media (min-width: 992px) {
.Hero_-_MXblk {
        padding-bottom: 0
}
}
@media (max-width: 991px) {
.Hero_-_MXblk {
        padding-top: 0;
        background-image: none
}
}
.Container_-_1Tyb6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    max-width: 600px;
    min-height: 600px;
    margin: auto;
    text-align: center
}
@media (min-width: 992px) {
.Container_-_1Tyb6 {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        max-width: none;
        text-align: left
}
}
.Content_-_cNq5O {
    width: 100%;
    max-width: 730px;
    margin-top: var(--space-lg)
}
@media (min-width: 992px) {
.Content_-_cNq5O {
        z-index: 1;
        width: 60%;
        max-width: none;
        margin: 0 -7% 0 0
}
}
.Headline_-_1V8aY {
    max-width: 730px;
    font-size: var(--font-size-h3);
    line-height: 1.5 /* TODO: Remove when line height updated */
}
@media (min-width: 600px) {
.Headline_-_1V8aY {
        font-size: var(--font-size-h2)
}
}
@media (min-width: 992px) {
.Headline_-_1V8aY {
        font-size: var(--font-size-h1)
}
}
.Button_-_1nxVb {
    text-transform: uppercase
}
.Button_-_1nxVb.desktop_-_23jUL {
        display: none
}
@media (min-width: 992px) {
.Button_-_1nxVb.desktop_-_23jUL {
            display: inline-block;
            margin-top: var(--space-xl)
}
}
.Button_-_1nxVb.mobile_-_2uDNg {
        display: inline-block;
        margin: var(--space-sm) auto 0 auto
}
@media (min-width: 992px) {
.Button_-_1nxVb.mobile_-_2uDNg {
            display: none
}
}
.SeeMoreText_-_3kYs4 {
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-semi);
    cursor: pointer
}
@media (max-width: 991px) {
.SeeMoreText_-_3kYs4 {
        margin-top: var(--space-lg)
}
}
.Media_-_337Sl {
    width: 100%
}
@media (min-width: 992px) {
.Media_-_337Sl {
        width: 45%
}
}
.Img_-_1suCW {
    width: 100%;
    margin: auto;
}
