
.Question_-_5l568 {
    display: grid;
    grid-template-columns: 62px 1fr;
    grid-column-gap: var(--space-md);
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end
}
.Question_-_5l568 + .Question_-_5l568 {
        margin-top: var(--space-xl);
}
.QuestionRight_-_3-CyC {
    grid-template-columns: 1fr 62px;
}
.Avatar_-_2QfHi {
    margin: 0;
}
.Bubble_-_jFECs {
    position: relative;
    padding: var(--space-md);
    line-height: 1.5;
    color: var(--color-white);
    background-color: var(--color-gray-500);
    border-radius: var(--border-radius)
}
.Bubble_-_jFECs span {
        color: var(--color-white);
        cursor: pointer;
}
.Bubble_-_jFECs::after {
        position: absolute;
        bottom: 0;
        display: block;
        width: 0;
        height: 0;
        content: "";
}
.Bubble_-_jFECs.question-0_-_2_VJT,
    .Bubble_-_jFECs.question-2_-_2ktME {
        margin-left: var(--space-lg)
}
.Bubble_-_jFECs.question-0_-_2_VJT::after, .Bubble_-_jFECs.question-2_-_2ktME::after {
            left: -30px;
            border-top: 24px solid transparent;
            border-right: 32px solid var(--color-gray-500);
}
.Bubble_-_jFECs.question-1_-_2G82P {
        margin-right: var(--space-lg)
}
.Bubble_-_jFECs.question-1_-_2G82P::after {
            right: -30px;
            border-top: 24px solid transparent;
            border-left: 32px solid var(--color-gray-500);
}
