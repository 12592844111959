
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}
.LandingPage_-_jql7T {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    background-color: var(--color-white);
}
.ElevatedContent_-_2NXqx {
    -webkit-box-shadow: var(--elevation-08);
            box-shadow: var(--elevation-08);
}
.squeeze_-_1nYDM {
    margin: -60px auto
}
@media (min-width: 600px) {
.squeeze_-_1nYDM {
        margin: -120px auto
}
}
