
.Login_-_2s4-t {
    display: block;
    padding-bottom: 80px;
}
.Title_-_2K0Vw {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-bold);
    color: var(--color-gray-500);
    text-align: center
}
@media (min-width: 600px) {
.Title_-_2K0Vw {
        font-size: var(--font-size-h2)
}
}
.Text_-_1Gyon {
    margin-bottom: var(--space-xxl);
    text-align: center;
}
.Grid_-_24LEV {
    max-width: 360px;
    margin: auto
}
@media (min-width: 780px) {
.Grid_-_24LEV {
        max-width: 720px
}
}
@media (min-width: 1200px) {
.Grid_-_24LEV {
        max-width: 1140px
}
}
.Item_-_2lk5o {
    text-align: center;
}
.ItemIcon_-_2KPFJ {
    height: 60px;
    margin-bottom: var(--space)
}
.ItemIcon_-_2KPFJ.rotation_-_31UBO {
        height: 70px;
        margin-bottom: var(--space);
}
.ItemTitle_-_AbtGi {
    margin-bottom: var(--space-md);
    font-weight: var(--font-weight-bold);
    color: var(--color-gray-500);
}
.NextSection_-_3qoxH {
    margin-top: 80px;
    font-size: var(--font-size-h3);
    font-weight: bold;
    color: var(--color-primary);
    text-align: center;
    cursor: pointer;
}
