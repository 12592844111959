
@-webkit-keyframes FadeFrames_-_pi0Yz {
from {
        opacity: 0;
}
to {
        opacity: 1;
}
}
@keyframes FadeFrames_-_pi0Yz {
from {
        opacity: 0;
}
to {
        opacity: 1;
}
}
.FadeIn_-_2iG0X {
    -webkit-animation: FadeFrames_-_pi0Yz 250ms;
            animation: FadeFrames_-_pi0Yz 250ms;
}
.FadeOut_-_1T1br {
    animation: FadeFrames_-_pi0Yz reverse 250ms;
}
.PositionAbsolute_-_1pKVo {
    position: absolute;
}
.PositionFixed_-_2Rf4o {
    position: fixed;
}
.Modal_-_2Y9T8 {
    --width: 95%;
    --padding: var(--space);

    top: 50px;
    right: 0;
    left: 0;
    z-index: 3;
    max-width: calc(var(--width) - var(--padding) * 2);
    margin: auto;
    background: var(--color-white);
    border-radius: 10px
}
.Modal_-_2Y9T8.OverflowHidden_-_2_vwt {
        overflow: hidden;
}
.Modal_-_2Y9T8.ExtraMargin_-_3OmOa {
        top: 100px;
}
.Icon_-_EDv0e {
    position: absolute;
    top: -50px;
    left: calc(50% - 50px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100px;
    height: 100px;
    font-size: 40px;
    color: var(--color-white);
    background: var(--color-yellow);
    border-radius: 50%;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)
}
.Icon_-_EDv0e > span {
        position: relative;
}
.Icon_-_EDv0e.Danger_-_2q8qU {
        background: var(--color-danger);
}
.ShowClose_-_3OEcF {
    display: block !important;
}
.Close_-_2uMEB {
    position: absolute;
    top: var(--padding);
    right: var(--padding);
    z-index: 3;
    display: none;
    cursor: pointer
}
.Close_-_2uMEB > .white_-_1s1Eh {
        color: var(--color-white) !important;
}
.Content_-_Owk3L {
    position: relative;
    z-index: 2;
}
.Circle_-_1S-Qa {
    --size: 467px;

    position: absolute;
    top: calc((var(--size) / 3) * -1);
    right: calc((var(--size) / 3) * -1);
    z-index: 1;
    width: var(--size);
    height: var(--size);
    background: rgba(251, 182, 73, 0.1);
    border-radius: 50%;
}
@media (min-width: 780px) {
.Modal_-_2Y9T8 {
        --width: 680px;
        --padding: var(--space-md);

        max-width: calc(var(--width) - var(--padding));
}
}
