:root {
    /* Space
    ---------------------------------------- */
    --space-flush: 0;
    --space: 16px;
    --space-xs: calc(0.25 * var(--space)); /* 4px */
    --space-sm: calc(0.5 * var(--space)); /* 8px */
    --space-reg: var(--space); /* 16px */
    --space-md: calc(1.5 * var(--space)); /* 24px */
    --space-lg: calc(2 * var(--space)); /* 32px */
    --space-xl: calc(3 * var(--space)); /* 48px */
    --space-xxl: calc(4 * var(--space)); /* 64px */
    --space-xxxl: calc(6 * var(--space)); /* 96px */
    --max-width: 1200px;
    --max-width-md: 980px;
    --max-width-sm: 680px;
    --max-width-xs: 540px;

    /* Colors
    ---------------------------------------- */

    /* Grays */
    --color-gray-1000: #000;
    --color-gray-900: #0f1013;
    --color-gray-800: #191a1e;
    --color-gray-700: #1f2024;
    --color-gray-600: #24262b;
    --color-gray-500: #292b30;
    --color-gray-400: #494b4f;
    --color-gray-300: #696b6e;
    --color-gray-200: #949598;
    --color-gray-100: #bfbfc1;
    --color-gray-085: #e5e6e6;
    --color-gray-070: #e9eaea;
    --color-gray-055: #edeeee;
    --color-gray-040: #f2f3f3;
    --color-gray-025: #f7f8f8;
    --color-gray-010: #fcfcfc;
    --color-gray-000: #fff;

    --color-soft-gray: rgb(63, 76, 89);
    --color-softer-gray: rgb(63, 76, 89, 0.5);

    --color-white: var(--color-gray-000);
    --color-black: var(--color-gray-1000);

    --color-black-012: rgba(0, 0, 0, 0.12);
    --color-black-014: rgba(0, 0, 0, 0.14);
    --color-black-020: rgba(0, 0, 0, 0.2);

    --color-semi-transparent-white: rgba(255, 255, 255, 0.5);

    /* Blues */
    --color-blue-900: #0084ba;
    --color-blue-800: #0094c4;
    --color-blue-700: #009dca;
    --color-blue-600: #00a7d0;
    --color-blue-500: #00aed5;
    --color-blue-400: #26badb;
    --color-blue-300: #4dc6e2;
    --color-blue-200: #80d7ea;
    --color-blue-100: #b3e7f2;
    --color-blue-50: #e0f5fa;

    /* Greens */
    --color-green-900: #379729;
    --color-green-800: #49a53a;
    --color-green-700: #53ad43;
    --color-green-600: #5db64d;
    --color-green-500: #65bc54;
    --color-green-400: #7cc66e;
    --color-green-300: #93d087;
    --color-green-200: #b2deaa;
    --color-green-100: #d1ebcc;
    --color-green-050: #edf7ea;

    /* Yellows */
    --color-yellow-900: #f88e21;
    --color-yellow-800: #f99e31;
    --color-yellow-700: #faa639;
    --color-yellow-600: #faaf42;
    --color-yellow-500: #fbb649;
    --color-yellow-400: #fcc164;
    --color-yellow-300: #fccc80;
    --color-yellow-200: #fddba4;
    --color-yellow-100: #fee9c8;
    --color-yellow-050: #fff6e9;

    /* Oranges */
    --color-orange-900: #e85120;
    --color-orange-800: #ec642f;
    --color-orange-700: #ee6e37;
    --color-orange-600: #f07940;
    --color-orange-500: #f28147;
    --color-orange-400: #f49463;
    --color-orange-300: #f6a77e;
    --color-orange-200: #f9c0a3;
    --color-orange-100: #fbd9c8;
    --color-orange-050: #fdf0e9;

    /* Reds */
    --color-red-900: #e82a20;
    --color-red-800: #ec3b2f;
    --color-red-700: #ee4437;
    --color-red-600: #f04e40;
    --color-red-500: #f25547;
    --color-red-400: #f46f63;
    --color-red-300: #f6887e;
    --color-red-200: #f9aaa3;
    --color-red-100: #fbccc8;
    --color-red-050: #fdebe9;

    /* Brand Colors */
    --color-green: var(--color-green-500);
    --color-red: var(--color-red-500);
    --color-orange: var(--color-orange-500);
    --color-yellow: var(--color-yellow-500);
    --color-blue: var(--color-blue-500);

    /* Semantic Colors */
    --color-primary: var(--color-blue-500);
    --color-primary-hover: var(--color-blue-900);
    --color-success: var(--color-green-500);
    --color-success-hover: var(--color-green-900);
    --color-danger: var(--color-red-500);
    --color-danger-hover: var(--color-red-900);
    --color-warning: var(--color-orange-500);
    --color-warning-hover: var(--color-orange-900);
    --color-yellow-hover: var(--color-yellow-900);

    --disabled-color: var(--color-gray-200);
    --disabled-bg: var(--color-gray-085);

    /* Type
    ---------------------------------------- */
    --font-color: var(--color-gray-500);
    --font-color-lighter: var(--color-soft-gray);

    --font-family: "lato", -apple-system, "Helvetica Neue", Arial, sans-serif;

    /* FontSize
    ---------------------------------------- */
    --font-size-display-1: 72px;
    --font-size-display-2: 64px;
    --font-size-display-3: 56px;
    --font-size-h1: 48px;
    --font-size-h2: 40px;
    --font-size-h3: 32px;
    --font-size-h4: 24px;
    --font-size-h5: 20px;
    --font-size-h6: 16px;
    --font-size: 16px;
    --font-size-p-lead: 20px;
    --font-size-text-sm: 14px;
    --font-size-text-xs: 11px;

    --font-weight: var(--font-weight-regular);
    --font-weight-hairline: 100;
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semi: 600;
    --font-weight-bold: 700;
    --font-weight-black: 900;

    --line-height-display-1: 80px;
    --line-height-display-2: 72px;
    --line-height-display-3: 64px;
    --line-height-h1: 56px;
    --line-height-h2: 48px;
    --line-height-h3: 40px;
    --line-height-h4: 32px;
    --line-height-h5: 28px;
    --line-height-h6: 24px;
    --line-height: 24px;
    --line-height-p-lead: 28px;
    --line-height-text-sm: 22px;

    /* Elevation
    ---------------------------------------- */
    --elevation-01:
        0 1px 1px 0 var(--color-black-012),
        0 2px 1px -1px var(--color-black-014),
        0 1px 3px 0 var(--color-black-020);
    --elevation-02:
        0 2px 2px 0 var(--color-black-012),
        0 3px 1px -2px var(--color-black-014),
        0 1px 5px 0 var(--color-black-020);
    --elevation-03:
        0 3px 4px 0 var(--color-black-012),
        0 3px 2px -2px var(--color-black-014),
        0 1px 8px 0 var(--color-black-020);
    --elevation-04:
        0 4px 5px 0 var(--color-black-012),
        0 1px 10px 0 var(--color-black-014),
        0 2px 4px -1px var(--color-black-020);
    --elevation-06:
        0 6px 10px 0 var(--color-black-014),
        0 1px 18px 0 var(--color-black-012),
        0 3px 5px -1px var(--color-black-020);
    --elevation-08:
        0 8px 10px 1px var(--color-black-012),
        0 3px 14px 2px var(--color-black-014),
        0 5px 5px -3px var(--color-black-020);
    --elevation-09:
        0 9px 12px 1px var(--color-black-012),
        0 3px 16px 2px var(--color-black-014),
        0 5px 6px -3px var(--color-black-020);
    --elevation-12:
        0 12px 17px 2px var(--color-black-012),
        0 5px 22px 4px var(--color-black-014),
        0 7px 8px -4px var(--color-black-020);
    --elevation-16:
        0 16px 24px 2px var(--color-black-012),
        0 6px 30px 5px var(--color-black-014),
        0 8px 10px -5px var(--color-black-020);
    --elevation-24:
        0 24px 38px 3px var(--color-black-012),
        0 9px 46px 8px var(--color-black-014),
        0 11px 15px -7px var(--color-black-020);

    /* Borders & Shadows
    ---------------------------------------- */
    --border-radius: 3px;
    --border-radius-lg: 14px;

    --border-circle: 50%;

    --border-color: var(--color-gray-200);
    --border: 1px solid var(--border-color);

    --border-color-faint: var(--color-gray-100);
    --border-faint: 1px solid var(--border-color-faint);

    --hover-border-color: var(--color-gray-300);
    --hover-border-faint-color: var(--color-gray-200);

    --focus-border-color: var(--color-primary);
    --focus-box-shadow: 0 0 4px 1px var(--color-blue-200);
    --focus-box-shadow-inset: inset 0 0 0 1px var(--color-primary);

    --danger-border-color: var(--color-danger);
    --danger-box-shadow: 0 0 4px 1px var(--color-red-200);
    --danger-box-shadow-inset: inset 0 0 0 1px var(--color-danger);

    --warning-border-color: var(--color-warning);
    --warning-box-shadow: 0 0 4px 1px var(--color-orange-200);
    --warning-box-shadow-inset: inset 0 0 0 1px var(--color-warning);

    --success-border-color: var(--color-success);
    --success-box-shadow: 0 0 4px 1px var(--color-green-200);
    --success-box-shadow-inset: inset 0 0 0 1px var(--color-success);

    /* Opacity
    ------------------------------------------ */
    --disabled-opacity: 0.65;

    /* Transition
    ---------------------------------------- */
    --transition-time: 0.2s;
    --transition-easing: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    --transition: var(--transition-time) var(--transition-easing);
    --transition-time-extended: 0.5s;
    --transition-extended: var(--transition-time-extended) var(--transition-easing);

    /* Breakpoints
    ---------------------------------------- */
    --mobile-min: 600px;
    --mobile-max: 779px;

    --tablet-min: 780px;
    --tablet-max: 991px;

    --tablet-lg-min: 992px;
    --tablet-lg-max: 1199px;

    --desktop-min: 1200px;

    /* X range. */
@custom-media --mobile (min-width: var(--mobile-min)) and (max-width: var(--mobile-max));
@custom-media --tablet (min-width: var(--tablet-min)) and (max-width: var(--tablet-max));
@custom-media --tablet-lg (min-width: var(--tablet-lg-min)) and (max-width: var(--tablet-lg-max));
@custom-media --desktop (min-width: var(--desktop-min)) and (max-width: var(--desktop-max));

    /* X and up. */
@custom-media --mobile-up (min-width: var(--mobile-min));
@custom-media --tablet-up (min-width: var(--tablet-min));
@custom-media --tablet-lg-up (min-width: var(--tablet-lg-min));
@custom-media --desktop-up (min-width: var(--desktop-min));

    /* X and down. */
@custom-media --mobile-down (max-width: var(--mobile-max));
@custom-media --tablet-down (max-width: var(--tablet-max));
@custom-media --tablet-lg-down (max-width: var(--tablet-lg-max));
@custom-media --desktop-down (min-width: 1px); /* everything */
}
.ColorBar_-_pkp4S {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 12px
}
@media (min-width: 992px) {
.ColorBar_-_pkp4S {
        height: 21px
}
}
.ColorBlock_-_3rZux {
    display: block;
    width: 25%;
}
.Blue_-_1PPUN {
    background-color: #00aed5;
    background-color: #00aed5;
    background-color: var(--color-blue);
}
.Green_-_1DsIB {
    background-color: #65bc54;
    background-color: #65bc54;
    background-color: var(--color-green);
}
.Yellow_-_34Izj {
    background-color: #fbb649;
    background-color: #fbb649;
    background-color: var(--color-yellow);
}
.Orange_-_2q-hH {
    background-color: #f28147;
    background-color: #f28147;
    background-color: var(--color-orange);
}
