
.Page_-_1itha {
    position: relative;
    width: 100%;
}
.ModalBlur_-_SwcqB {
    -webkit-filter: blur(6px);
            filter: blur(6px);
    -webkit-transition: -webkit-filter 1s;
    transition: -webkit-filter 1s;
    transition: filter 1s;
    transition: filter 1s, -webkit-filter 1s;
}
.ModalBackdrop_-_3bXYT {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: rgba(80, 80, 80, 0.5);
}
.AlertBar_-_11uL7 {
    position: sticky;
    top: 0;
    z-index: 3;
}
.Sidebar_-_OFrAM {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 240px;
            flex: 0 0 240px;
    padding: var(--space);
}
.Content_-_GyAHD {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    max-width: 1440px;
    padding: var(--space-lg) 0;
    margin: 0 auto
}
.Content_-_GyAHD.Flush_-_3FHSy {
        padding: 0;
}
.Content_-_GyAHD.Centered_-_3WNFF {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.Title_-_2D-9z {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin-bottom: var(--space-md);
    font-size: var(--font-size-h4)
}
.Title_-_2D-9z h1,
    .Title_-_2D-9z h2 {
        /* TODO: Change value to var(--font-weight-bold) */
        font-weight: 600;
}

/* Sticky Header Animation */
.animation_active_-_SHIdh {
    -webkit-transition: var(--transition-extended);
    transition: var(--transition-extended);
}
.animation_enter_-_TcsTf,
.animation_leave-to_-_1erUF {
    -webkit-transform: translateX(0%) translateY(-100%);
            transform: translateX(0%) translateY(-100%);
}
.animation_enter-to_-_1qiFp,
.animation_leave_-_3QH1W {
    -webkit-transform: translateX(0%) translateY(0);
            transform: translateX(0%) translateY(0);
}

/* Content height formula screen height - footer height.

/* 5K */
@media (min-height: 2880px) {
.Content_-_GyAHD {
        min-height: calc(2880px - 720px);
}
}

/* 4k */
@media (min-height: 2160px) {
.Content_-_GyAHD {
        min-height: calc(2160px - 720px);
}
}

/* "HD" */
@media (min-height: 1080px) {
.Content_-_GyAHD {
        min-height: calc(1080px - 720px);
}
}
