
.Footer_-_1AgF9 {
    position: relative;
    background: var(--color-gray-500);
}
.FooterSection_-_1fEY8 {
    padding: 60px 0
}
@media (max-width: 991px) {
.FooterSection_-_1fEY8 {
        padding: 30px 0
}
}

/* Icon bar
---------------------------------------------- */
.IconBar_-_3GA_G {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: var(--space-lg);
    border-bottom: var(--border)
}
@media (min-width: 992px) {
.IconBar_-_3GA_G {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        padding-bottom: 60px
}
}
.Logo_-_2rtcp {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 90px;
    margin-bottom: var(--space-lg)
}
@media (min-width: 992px) {
.Logo_-_2rtcp {
        margin-bottom: 0
}
}
.Social_-_1eNw6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.Icon_-_1Cd-6 {
    width: 30px
}
.Icon_-_1Cd-6 + .Icon_-_1Cd-6 {
        margin-left: var(--space)
}
@media (min-width: 600px) {
.Icon_-_1Cd-6 + .Icon_-_1Cd-6 {
            margin-left: var(--space-lg)
}
}

/* Content
---------------------------------------------- */
.Content_-_3QW-K {
    display: none
}
@media (min-width: 992px) {
.Content_-_3QW-K {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        margin: var(--space-xl) 0 var(--space-xxxl) 0
}
}
.Nav_-_3L7Gn {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    margin-right: var(--space-lg)
}
.Nav_-_3L7Gn h3 {
        font-weight: var(--font-weight-bold);
        color: var(--color-gray-085);
}
.Nav_-_3L7Gn a {
        font-weight: var(--font-weight-regular);
        color: var(--color-gray-085) !important;
        margin-left: 0 !important
}
.Nav_-_3L7Gn a:hover {
            color: var(--color-gray-100) !important;
}
.Spacer_-_2rIM_ {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.About_-_15T9r {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 400px;
            flex: 0 0 400px;
}

/* Address & Copyright
---------------------------------------------- */
.Address_-_3EaIP,
.Copyright_-_3Y_2T {
    color: var(--color-gray-085);
    text-align: center
}
@media (min-width: 992px) {
.Address_-_3EaIP,
.Copyright_-_3Y_2T {
        text-align: left
}
}
@media (max-width: 991px) {
.Address_-_3EaIP,
.Copyright_-_3Y_2T {
        font-size: 10px
}
}
.Address_-_3EaIP {
    margin-top: var(--space-lg)
}
@media (min-width: 992px) {
.Address_-_3EaIP {
        margin-top: 0
}
}
@media (max-width: 991px) {
.Address_-_3EaIP {
        font-size: 10px
}
}

/* ColorBar
---------------------------------------------- */
.FooterColorBar_-_3sJUh {
    margin: auto;
}
@media (max-width: 991px) {
.CopyInfo_-_SPRCv {
        margin-top: var(--space-lg);
}
}
