
.Title_-_1-tgp {
    font-size: var(--font-size-h3);
    text-align: center
}
@media (min-width: 780px) {
.Title_-_1-tgp {
        font-size: var(--font-size-h2)
}
}
.Text_-_3v2DW {
    text-align: center;
}
.Card_-_36vQH {
    color: var(--color-gray-200);
    background-color: var(--color-white)
}
.Card_-_36vQH + .Card_-_36vQH {
        margin-top: var(--space-md);
}
@media (min-width: 780px) {
.CardContainer_-_2TrYi {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center
}
}
@media (min-width: 780px) {
.CardContent_-_tilpZ {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1
}
}
@media (min-width: 780px) {
.CardAction_-_2DCN1 {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        margin-left: var(--space-md)
}
}
.CardButton_-_1X0Wd {
    display: block;
    width: 100%
}
@media (min-width: 780px) {
.CardButton_-_1X0Wd {
        display: inline-block
}
}
.CardTitle_-_1Y0dt {
    color: var(--color-gray-500);
}
.Options_-_1hdnH {
    margin-top: var(--space);
}
.Option_-_1DX_I {
    background-color: #f8f8f8;
}
.Flex_-_3ySUd {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.OptionTitle_-_2SSmZ {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.OptionPrice_-_3GJoT {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    color: var(--color-primary);
}
.OptionTerm_-_373eR {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
}
.mobile_break_-_srKDA {
    display: none
}
@media (max-width: 991px) {
.mobile_break_-_srKDA {
        display: block
}
}
