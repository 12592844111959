
.Header_-_2gOf1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0 var(--space)
}
.Header_-_2gOf1.standard_-_2lpGO {
        height: 80px;
        background: var(--color-gray-500);
}
.Header_-_2gOf1.landing-page_-_2WlD- {
        height: 96px;
        background: var(--color-primary);
}

/* Tablet and upward. */
@media only screen and (min-width: 780px) {
.Header_-_2gOf1 {
        padding: 0 var(--space-xxl);
}
}
