
.Menu_-_2zkXT {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.Links_-_1z9wB {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
}
.MenuLink_-_1qdU9 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 70px;
    height: 80px;
    font-size: var(--font-size-h4);
    color: var(--color-white)
}
.MenuLink_-_1qdU9.ActiveLink_-_3DpbF {
        color: var(--font-color);
        background: var(--color-white);
}
.UserMenuContainer_-_1IejV {
    position: relative;
    display: none;
    width: 271px;
    height: 48px;
    z-index: 10;
}

/* Tablet and upward. */
@media only screen and (min-width: 780px) {
.HamburgerMenu_-_1J_bD {
        display: none;
}
.Links_-_1z9wB {
        margin-right: 0;
}
.UserMenuContainer_-_1IejV {
        display: block;
}
}
