
.Header_-_36v78 {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    background: var(--color-primary);
    -webkit-box-shadow: var(--elevation-03);
            box-shadow: var(--elevation-03);
}
.Content_-_2c9WA {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 1440px;
    padding: var(--space) var(--space-md);
    margin: 0 auto;
}

/* Logo */
.LogoLink_-_3JOGr {
    display: block;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.Logo_-_2qa5F {
    display: block;
    width: 222px;
    color: var(--color-white)
}
@media (max-width: 779px) {
.Logo_-_2qa5F {
        width: 66px
}
}

/* Actions */
.Actions_-_uvyIX {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    margin-left: var(--space);
}
.LoginLink_-_ZnkO7 {
    margin-right: var(--space);
    font-weight: var(--font-weight-medium);
    color: var(--color-white);
    text-decoration: none;
    text-transform: uppercase;
}
.Button_-_2A_nb {
    width: 100%;
    text-transform: uppercase;
}
