
.Logo_-_3nK2V {
    width: 66px;
    height: 40px;
}
.Logo_-_3nK2V a,
.Logo_-_3nK2V a:visited {
    color: var(--color-white);
}

/* Tablet and upward. */
@media only screen and (min-width: 780px) {
.Logo_-_3nK2V {
        width: 223px;
}
}
