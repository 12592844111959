
.Carousel_-_35bUs {
    max-width: 420px;
    margin: auto
}
@media (min-width: 992px) {
.Carousel_-_35bUs {
        max-width: none
}
}
.Icon_-_3Tzo8 {
    display: none
}
@media (min-width: 600px) {
.Icon_-_3Tzo8 {
        display: block;
        width: 90px;
        margin: auto
}
}
@media (min-width: 992px) {
.Icon_-_3Tzo8 {
        margin: 0
}
}
.Browsers_-_Z4i_C {
    display: none
}
@media (min-width: 600px) {
.Browsers_-_Z4i_C {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center
}
}
@media (min-width: 992px) {
.Browsers_-_Z4i_C {
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start
}
}
.Browser_-_3zPSn {
    height: 25px
}
.Browser_-_3zPSn + .Browser_-_3zPSn {
        margin-left: var(--space-md);
}
